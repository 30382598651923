import { datadogRum, type RumInitConfiguration } from "@datadog/browser-rum";

const KEYS = [
  "applicationId",
  "clientToken",
  "version",
  "env",
  "service",
  "site",
] as const;

type DatadogConfig = Pick<RumInitConfiguration, (typeof KEYS)[number]>;

function isValidConfig(value: unknown): value is DatadogConfig {
  return (
    typeof value === "object"
    && !!value
    && KEYS.every((key) => {
      if (key in value) {
        const val = value[key as keyof typeof value];
        return typeof val === "string" && !!val;
      }

      return false;
    })
  );
}

// TODO: Go through available options here https://docs.datadoghq.com/real_user_monitoring/browser/
export default defineNuxtPlugin(
  ({
    $config: {
      public: { datadog },
    },
  }) => {
    if (!isValidConfig(datadog)) {
      console.warn("Invalid Datadog configuration", datadog);
      return;
    }

    datadogRum.init({
      ...datadog,
      sessionSampleRate: 100,
      sessionReplaySampleRate: datadog.env === "prod" ? 0 : 100,
      trackUserInteractions: datadog.env !== "prod",
      trackResources: datadog.env !== "prod",
      trackLongTasks: datadog.env !== "prod",
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [
        window.location.origin,
      ],
    });
  },
);
