<script setup lang="ts">
import { KsSpriteSheet } from "@aschehoug/kloss";

const previewMode = usePreviewMode();

const script = previewMode.enabled.value
  ? [
      {
        src: "https://app.crystallize.com/static/frontend-preview-listener.js",
        defer: true,
      },
    ]
  : [];

const { env } = useRuntimeConfig().public;

const isDebug = useDebugMode();

useHead({
  htmlAttrs: {
    lang: "no",
    prefix: "og: https://ogp.me/ns#",
    class: {
      debug: isDebug,
    },
  },
  script,
  meta:
    env === "prod"
      ? []
      : [{ name: "robots", content: "noindex, nofollow" }],
});
</script>

<template>
  <NuxtLayout>
    <KsSpriteSheet />
    <NuxtPage />
  </NuxtLayout>
</template>
