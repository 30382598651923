declare const window: Window & {
  debug: () => void;
};

export function useDebugMode() {
  const config = useRuntimeConfig();

  const debugCookie = useCookie("__debug", {
    default: () => false,
  });

  const isProd = computed(() => config.public.env === "prod");

  if (isProd.value) {
    debugCookie.value = false;
  }
  else {
    function handleDebug() {
      const all = document.querySelectorAll("*");
      if (debugCookie.value) {
        all.forEach(el => el.setAttribute("data-debug-tag", el.tagName.toLowerCase()));
      }
      else {
        all.forEach(el => el.removeAttribute("data-debug-tag"));
      }
    }

    onBeforeMount(() => {
      window.debug = () => {
        debugCookie.value = !debugCookie.value;
      };

      handleDebug();
    });

    watch(debugCookie, handleDebug);
  }

  return debugCookie;
}
